
import { IonItem, IonList, IonProgressBar, IonBadge } from '@ionic/vue';
import AvatarHappy from '@/components/Avatar/Happy.vue';
import AvatarUnhappy from '@/components/Avatar/Unhappy.vue';
import { defineComponent } from 'vue';
import libStore from "@/lib/libStore";
import Moods from "@/lib/const";
import moment from "moment";

export default defineComponent({
  name: "Tab2MoodSelect",
  components: {
    AvatarHappy,
    AvatarUnhappy,
    IonItem,
    IonList,
    IonBadge,
    IonProgressBar
  },
  computed: {
    stats() {
      // max minutes to 1day
      const maxMinutes = 1440;
      let happyTime = 0;
      let sadTime = 0;
      let totalTime = 0;
      for (let i = 0; i < this.moodList.length; i++) {
        let minutes = 0;
        let diff = 0;
        const now = (this.moodList[i].timestampEnd)? this.moodList[i].timestampEnd : moment().unix();
        diff =  Math.abs(now - this.moodList[i].timestampStart);
        minutes = Math.floor(diff/60);
        minutes = (minutes > maxMinutes)? maxMinutes : minutes;
        if(this.moodList[i].name == Moods.HAPPY) {
          happyTime = happyTime + minutes;
        } else {
          sadTime = sadTime + minutes;
        }
        totalTime = totalTime + minutes;
      }
      if(totalTime)
      {
        const smileProgress = Math.floor((happyTime/totalTime)*100)
        return {
          smile: (smileProgress/100),
          sad: ((100 - smileProgress)/100)
        }
      }
      return {smile: 0, sad: 0}
    }
  },
  setup() {
    const { moodList } = libStore();
    return {
      moodList
    }
  }
})
