
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/vue';
import { IonIcon, IonItem, IonText, IonButton } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { IonModal } from '@ionic/vue';
import { pencilOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import AvatarHappy from '@/components/Avatar/Happy.vue';
import AvatarUnhappy from '@/components/Avatar/Unhappy.vue';
import Moods from "@/lib/const";
import textDuration from "@/lib/textDuration";
import Modal from "@/components/Modal/Edit.vue";

interface CardItem {
  name: string;
  extra: string;
  dateStart: string;
  timestampStart: number;
  timestampEnd: number | null;
}

export default defineComponent({
  name: "Tab2MoodCard",
  props: {
    item: {
      type: Object as () => CardItem,
      require: true
    }
  },
  components: {
    AvatarHappy,
    AvatarUnhappy,
    Modal,
    IonModal,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonItem,
    IonText,
    IonGrid,
    IonRow,
    IonCol
  },
  setup() {
    const typeHappy = Moods.HAPPY;
    const typeUnhappy = Moods.SAD;
    const textHappy = Moods.TEXT_HAPPY;
    const textSad = Moods.TEXT_SAD;

    const isModalOpen = ref(false);
    const setModalOpen = (state: boolean) => isModalOpen.value = state;

    return {
      pencilOutline,
      isModalOpen,
      setModalOpen,
      typeHappy,
      typeUnhappy,
      textHappy,
      textSad
    }
  },
  computed: {
    type() {
      if (this.item) {
        return this.item.name
      }
      return ''
    },
    timeAgo() {
      if (this.item && this.item.timestampEnd) {
        return textDuration(this.item.timestampEnd, this.item.timestampStart);
      }
      return ''
    }
  },
  methods: {
    close() {
      this.setModalOpen(false);
    },
    openModal() {
      this.setModalOpen(true);
    },
    setModalData() {
      if (this.$refs['modal']) {
        (this.$refs['modal'] as any).loadItem();
      }
    }
  }
});
