
import {alertController, IonButton, IonIcon} from '@ionic/vue';
import {cloudDownloadOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {Directory, Encoding, Filesystem} from '@capacitor/filesystem';
import libStore from "@/lib/libStore";
import openToast from "@/lib/toastFail";
import textDuration from "@/lib/textDuration";
import webDownload from "@/lib/webDownload";
import moment from "moment";
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: "Tab2Download",
  components: {
    IonButton,
    IonIcon
  },
  setup() {
    const { moodList } = libStore();
    return {
      moodList,
      cloudDownloadOutline
    }
  },
  methods: {
    async download() {
      const alert = await alertController
          .create({ header: 'Save you history file as',
            inputs: [{ placeholder: 'my-file-name-here', name: 'file'}],
            buttons: [
                {text: 'Cancel',role: 'cancel', cssClass: 'secondary'},
                {text: 'Ok', handler: (alertData) => this.start(alertData) },
            ]
          });
      return alert.present();
    },
    start(alertData: {file: string}) {
       if (alertData.file) {
         this.writeFile(alertData.file)
       } else {
         openToast('Download Failed! No file name provided')
       }
    },
    async writeFile(filename: string) {
      const newFilename = this.slugify(filename)+'.csv';
      const data = this.fileData();
      // If running on the web...
      if (!isPlatform('hybrid')) {
        webDownload(data, newFilename, 'text/csv;encoding:utf-8');
      } else {
        return await Filesystem.writeFile({
          path: newFilename,
          data: data,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });
      }
    },
    fileData() {
      const array = this.moodList;
      let str = 'Mood,Duration,"Human Date","ISO Date",Extra,"TimeStamp Start","TimeStamp End",\r\n';
      for (let i = 0; i < array.length; i++)
      {
        const mood = (array[i].name)? array[i].name : '';
        const duration = (array[i].timestampEnd)? this.timeAgo(array[i]) : '';
        const date = (array[i].dateStart)? '"'+array[i].dateStart+'"' : '';
        const dateISO = (array[i].timestampStart)? '"'+ this.dateISO(array[i]) +'"' : '';
        const extra = (array[i].extra)? '"'+this.removeEnter(array[i].extra)+'"' : '';
        const timestampStart = (array[i].timestampStart)? '"'+array[i].timestampStart+'"' : '';
        const timestampEnd = (array[i].timestampEnd)? '"'+array[i].timestampEnd+'"' : '';
        str += mood+','+duration+','+date+','+dateISO+','+extra+','+timestampStart+','+timestampEnd+','+'\r\n';
      }
      return str;
    },
    slugify(filename: string)
    {
      return filename.toString().toLowerCase()
          .replace('.csv', '')            // Remove .csv from name
          .replace(/\s+/g, '-')           // Replace spaces with -
          .replace(/[^\w-]+/g, '')       // Remove all non-word chars
          .replace(/-+/g, '-')         // Replace multiple - with single -
          .replace(/^-+/, '')             // Trim - from start of text
          .replace(/-+$/, '');            // Trim - from end of text
    },
    dateISO(obj: {timestampStart: number}) {
      return moment.unix(obj.timestampStart).toISOString()
    },
    timeAgo(obj: {timestampStart: number; timestampEnd: number}) {
      return textDuration(obj.timestampEnd, obj.timestampStart);
    },
    removeEnter(string: string) {
      return string.replace(/[\n\r]+/g, '');
    }
  }
})
