
import { IonList, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import Moods from "@/lib/const";
import libStore from "@/lib/libStore";

export default defineComponent({
  name: "Tab2MoodSelect",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const { store, filterMood } = libStore();
    return {
      store,
      Moods: Moods,
      selected: filterMood
    }
  },
  methods: {
    change(val: any) {
      if (val.detail.value) {
        this.store.commit('moods/setFilterMood', val.detail.value)
        this.store.dispatch('moods/getMoodList')
      }
    }
  }
})
