<template>
  <ion-card>
    <ion-item>
      <AvatarHappy v-if="typeHappy === type" slot="start" />
      <AvatarUnhappy v-if="typeUnhappy === type" slot="start" />
      <ion-card-header>
        <ion-card-title>
          <span v-if="typeHappy === type">{{textHappy}}</span>
          <span v-if="typeUnhappy === type">{{textSad}}</span>
        </ion-card-title>
        <ion-card-subtitle color="green">{{item.dateStart}}</ion-card-subtitle>
      </ion-card-header>
    </ion-item>
    <ion-card-content>
      <ion-grid :fixed="true">
        <ion-row class="ion-align-items-center">
          <ion-col size="12">
            <ion-text>{{item.extra}}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col size="4" class="ion-text-left">
            <ion-button expand="full" fill="clear" @click="openModal">
              <ion-icon :icon="pencilOutline" slot="start"></ion-icon>
              <span>Edit</span>
            </ion-button>
          </ion-col>
          <ion-col size="8" class="ion-text-right">
            <ion-text color="danger">{{timeAgo}}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
    <ion-modal :is-open="isModalOpen" @willPresent="setModalData">
      <Modal :item="item" ref="modal" v-on:close="close" />
    </ion-modal>
  </ion-card>
</template>

<script lang="ts">
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/vue';
import { IonIcon, IonItem, IonText, IonButton } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { IonModal } from '@ionic/vue';
import { pencilOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import AvatarHappy from '@/components/Avatar/Happy.vue';
import AvatarUnhappy from '@/components/Avatar/Unhappy.vue';
import Moods from "@/lib/const";
import textDuration from "@/lib/textDuration";
import Modal from "@/components/Modal/Edit.vue";

interface CardItem {
  name: string;
  extra: string;
  dateStart: string;
  timestampStart: number;
  timestampEnd: number | null;
}

export default defineComponent({
  name: "Tab2MoodCard",
  props: {
    item: {
      type: Object as () => CardItem,
      require: true
    }
  },
  components: {
    AvatarHappy,
    AvatarUnhappy,
    Modal,
    IonModal,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonItem,
    IonText,
    IonGrid,
    IonRow,
    IonCol
  },
  setup() {
    const typeHappy = Moods.HAPPY;
    const typeUnhappy = Moods.SAD;
    const textHappy = Moods.TEXT_HAPPY;
    const textSad = Moods.TEXT_SAD;

    const isModalOpen = ref(false);
    const setModalOpen = (state: boolean) => isModalOpen.value = state;

    return {
      pencilOutline,
      isModalOpen,
      setModalOpen,
      typeHappy,
      typeUnhappy,
      textHappy,
      textSad
    }
  },
  computed: {
    type() {
      if (this.item) {
        return this.item.name
      }
      return ''
    },
    timeAgo() {
      if (this.item && this.item.timestampEnd) {
        return textDuration(this.item.timestampEnd, this.item.timestampStart);
      }
      return ''
    }
  },
  methods: {
    close() {
      this.setModalOpen(false);
    },
    openModal() {
      this.setModalOpen(true);
    },
    setModalData() {
      if (this.$refs['modal']) {
        (this.$refs['modal'] as any).loadItem();
      }
    }
  }
});
</script>

<style scoped>
.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}
ion-card-content{
  padding-inline-end: 6px;
  padding-inline-start: 6px;
}
ion-button{
  margin-left: calc(-1 * calc(var(--padding-start) + var(--padding-end)));
}
</style>