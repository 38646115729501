
import { IonList, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import libStore from "@/lib/libStore";
import Moods from "@/lib/const";

export default defineComponent({
  name: "Tab2PeriodList",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const { store, filterPeriod } = libStore();
    return {
      store,
      Moods: Moods,
      selected: filterPeriod
    }
  },
  methods: {
    change(val: any) {
      if (val.detail.value) {
        this.store.commit('moods/setFilterPeriod', val.detail.value)
        this.store.dispatch('moods/getMoodList')
      }
    }
  }
})
