import moment from "moment";

export default function (timestampEnd: number, timestampStart: number) {
    const ms = moment.unix(timestampEnd).diff(moment.unix(timestampStart));
    const duration = moment.duration(ms);
    const d = (duration.get("days") === 0) ? '' : duration.get("days") + ' days ';
    const h = (duration.get("hours") === 0) ? '' : duration.get("hours") + ' hrs ';
    const m = (duration.get("minutes") === 0) ? '' : duration.get("minutes") + ' min ';
    const s = (duration.get("seconds") === 0) ? '' : duration.get("seconds") + ' sec. ';
    return d+h+m+s;
}