
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonTextarea } from '@ionic/vue';
import { IonList, IonItem, IonLabel, IonRadioGroup, IonRadio } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import {defineComponent} from 'vue';
import AvatarHappy from '@/components/Avatar/Happy.vue';
import AvatarUnhappy from '@/components/Avatar/Unhappy.vue';
import Moods from "@/lib/const";
import libStore from "@/lib/libStore";
import {MoodUpdateItem} from "@/interface/Mood/Update";

export default defineComponent({
  name: 'ModalEdit',
  props: {
    item: {
      type: Object as () => MoodUpdateItem,
      required: true
    }
  },
  components: {
    AvatarHappy,
    AvatarUnhappy,
    IonContent,
    IonHeader,
    IonTitle,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonTextarea,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol
  },
  emits:['close'],
  setup() {
    const { store } = libStore()
    return {
      store,
      Moods: Moods
    }
  },
  computed: {
    payload(): MoodUpdateItem {
      return {
        id: this.item.id,
        name: this.mood,
        extra: this.content
      }
    }
  },
  data() {
    return {
      mood: '',
      content: ''
    }
  },
  methods: {
    send() {
      this.store.dispatch('moods/updateMood', this.payload)
      this.close()
    },
    loadItem() {
      this.mood = this.item.name
      this.content = this.item.extra
    },
    close() {
      this.$emit('close');
    }
  }
});
