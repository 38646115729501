<template>
  <ion-list>
    <ion-item>
      <ion-label>Choose a Period</ion-label>
      <ion-select placeholder="Select One" :value="selected" v-on:ionChange="change">
        <ion-select-option :value="Moods.DAY">{{Moods.DAY_TEXT}}</ion-select-option>
        <ion-select-option :value="Moods.WEEK">{{Moods.WEEK_TEXT}}</ion-select-option>
        <ion-select-option :value="Moods.MONTH">{{Moods.MONTH_TEXT}}</ion-select-option>
        <ion-select-option :value="Moods.YEAR">{{Moods.YEAR_TEXT}}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>
</template>

<script lang="ts">
import { IonList, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import libStore from "@/lib/libStore";
import Moods from "@/lib/const";

export default defineComponent({
  name: "Tab2PeriodList",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const { store, filterPeriod } = libStore();
    return {
      store,
      Moods: Moods,
      selected: filterPeriod
    }
  },
  methods: {
    change(val: any) {
      if (val.detail.value) {
        this.store.commit('moods/setFilterPeriod', val.detail.value)
        this.store.dispatch('moods/getMoodList')
      }
    }
  }
})
</script>

<style scoped>

</style>