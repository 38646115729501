
import {IonPage, IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { computed } from "vue";
import { onIonViewWillEnter } from '@ionic/vue';
import MoodCard from '@/components/Tab2/MoodCard.vue';
import MoodSelect from '@/components/Tab2/MoodSelect.vue';
import MoodStats from '@/components/Tab2/MoodStats.vue';
import PeriodSelect from '@/components/Tab2/PeriodSelect.vue';
import Download from '@/components/Tab2/Download.vue';
import Logout from '@/components/Logout.vue';
import libStore from "@/lib/libStore";
import toastFail from "@/lib/toastFail";
import loader from "@/lib/loader";

export default defineComponent({
  name: 'Tab2',
  components: {
    MoodCard,
    MoodSelect,
    MoodStats,
    PeriodSelect,
    Download,
    Logout,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonGrid,
    IonRow,
    IonCol
  },
  ionViewDidEnter() {
    this.loadApi()
  },
  setup () {
    const { store, currentUser, goToLogin, setUserId } = libStore()
    onIonViewWillEnter(() => {
      goToLogin()
      setUserId()
    })
    const { error, loading } = loader()
    const list = computed(() => store.getters['moods/list'])

    return {
      store,
      currentUser,
      list,
      loading,
      error
    }
  },
  watch: {
    loading(val) {
      if (!val) {
        this.completeRefresh()
      }
    },
    error(val) {
      if (val) {
        toastFail('Your Bank Happiness History Failed to Load, please pull to refresh.')
      }
    }
  },
  data() {
    return {
      refresher: null as any
    }
  },
  methods: {
    title(user: { email: string }) {
      return (user)? user.email : null
    },
    loadApi() {
      if (this.store.getters['moods/userId']) {
        this.store.dispatch('moods/getMoodList')
      }
    },
    doRefresh(event: CustomEvent) {
      this.refresher = event
      this.loadApi()
    },
    completeRefresh() {
      if (this.refresher && this.refresher.target) {
        this.refresher.target.complete()
        this.refresher = null
      }
    }
  }
});
