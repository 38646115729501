<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Edit Mood To</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-grid :fixed="true">
      <ion-row class="ion-align-items-center">
        <ion-col size="12">
          <ion-list>
            <ion-radio-group v-model="mood">
              <ion-item>
                <AvatarHappy slot="start" />
                <ion-label> {{Moods.TEXT_HAPPY}} </ion-label>
                <ion-radio color="success" slot="end" :value="Moods.HAPPY"></ion-radio>
              </ion-item>
              <ion-item>
                <AvatarUnhappy slot="start" />
                <ion-label> {{Moods.TEXT_SAD}} </ion-label>
                <ion-radio color="warning" slot="end" :value="Moods.SAD"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col size="12">
          <ion-item>
            <ion-textarea v-model="content" placeholder="The reason for my mood change is..."></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col offset="4" size="3">
          <ion-button fill="clear" @click="close">CANCEL</ion-button>
        </ion-col>
        <ion-col offset="1" size="4">
          <ion-button color="success" expand="full" @click="send">UPDATE</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonTextarea } from '@ionic/vue';
import { IonList, IonItem, IonLabel, IonRadioGroup, IonRadio } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import {defineComponent} from 'vue';
import AvatarHappy from '@/components/Avatar/Happy.vue';
import AvatarUnhappy from '@/components/Avatar/Unhappy.vue';
import Moods from "@/lib/const";
import libStore from "@/lib/libStore";
import {MoodUpdateItem} from "@/interface/Mood/Update";

export default defineComponent({
  name: 'ModalEdit',
  props: {
    item: {
      type: Object as () => MoodUpdateItem,
      required: true
    }
  },
  components: {
    AvatarHappy,
    AvatarUnhappy,
    IonContent,
    IonHeader,
    IonTitle,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonTextarea,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol
  },
  emits:['close'],
  setup() {
    const { store } = libStore()
    return {
      store,
      Moods: Moods
    }
  },
  computed: {
    payload(): MoodUpdateItem {
      return {
        id: this.item.id,
        name: this.mood,
        extra: this.content
      }
    }
  },
  data() {
    return {
      mood: '',
      content: ''
    }
  },
  methods: {
    send() {
      this.store.dispatch('moods/updateMood', this.payload)
      this.close()
    },
    loadItem() {
      this.mood = this.item.name
      this.content = this.item.extra
    },
    close() {
      this.$emit('close');
    }
  }
});
</script>

<style scoped>
</style>